.GetInTouchForm
{
    padding-top: 45px;
    background-color: #F9FBFD;
    border: 1px solid #EBEFFC;
    padding-left: 25px;
    padding-right: 36px;
    padding-bottom: 42px;
    width: 485px;
    margin-bottom: 60px;
}
.GetInTouchForm label{
    position: relative;
top: 28px;
right: 13px;
font-family: "Poppins";
font-size: 12px;
color: #426B87 !important;
}
.GetInTouchForm form{
    width: 423px;
}
.GetInTouchForm .button button{
    background-color: #426B87;
    color: #fff;
    border: none;
    height: 50px;
    margin-top: 35px;
}
.GetInTouchForm input
{
    height: 61px;
}
.GetInTouchForm textarea
{
    resize: none;
    padding-top: 25px;
}
.GetInTouchForm p{
    margin-bottom: 36px;
    font-size: 14px;
    opacity: 0.67;
}
@media only screen and (max-width: 768px) {
    .GetInTouchForm form{
        width: 100%;
    }
    .GetInTouchForm {
        width: 100%;
    }
}
@media  (min-width: 768px) and (max-width: 991px) {
    .GetInTouchForm form{
        width: 318px;
    }
    .GetInTouchForm {
        padding-right: 25px;
        position: relative;
        left: 30px;
    }
}