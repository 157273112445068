.input
{
    padding-left: 20px !important;
    padding-top: 20px !important;
    border: 1px solid #EBEFFC !important;
    height: 60px !important;

}
.textarea{
    padding-left: 20px;
    padding-top: 30px;
    border: 1px solid #EBEFFC;   
}
.input:focus-visible{
    outline: none;
    box-shadow: none;
}
label {
    position: relative;
    top: 28px;
    left: 13px;
    font-family: "Poppins";
    font-size: 12px;
    color: #426B87 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}