.Title .dark {
    position: relative;
    bottom: 46px;
    left: 22px;
    font-size: 18px;
    color: #426B87;
    font-family: 'Poppins', sans-serif;
}
.Title
{
    height: 205px;
}
.Title .light {
    position: relative;
    bottom: 46px;
    left: 22px;
    font-size: 18px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.Title h1 {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  position: relative;
  bottom: 45px;
  left: 15px;
}
.Title.dark h1 {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  position: relative;
  bottom: 39px;
  color: #fff;
  left: 11px;
}
.Title .gensqare
{
    position: relative;
    right: 200px;
    top: 33px;
    
}
.Title .arItgensqare
{
    position: relative;
    left: 130px;
    top: 33px;
    
}

.Title .itsqare
{
    position: relative;
    right: 229px;
    top: 33px;
}
.Title .arItsqare{
    position: relative;
    left: 0;
    top: 33px;
}
.dark .sqare
{
    position: relative;
    right: 230px;
    top: 35px;
}
.Title .arsqare
{
    position: relative;
    left:90px !important;
    top: 33px;
}
@media only screen and (max-width: 768px) {
    .Title h1 {
     width: 90%;
      }
      .Title.dark h1 {
     width: 90%;
     font-size: 25px;
      }
    .Title p {
     width: 90%;
      }
}
@media  (min-width: 768px) and (max-width: 991px) {
    .Title h1 {
        width: 90%;
        font-size: 25px;
         }
       .Title p {
        width: 90%;
         } 
}

