.posts
{
    padding-top: 50px;
    padding-bottom: 100px;
}
.posts .user {
    border: 2px solid black;
    border-radius: 10px;
    background-color: #b3e3ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 500px;
    height: 300px;
    margin: 20px;
  }
  
  .posts .paginationBttns {
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .posts .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #F0F0F0;
    cursor: pointer;
  }
  
  .posts .paginationBttns a:hover {
    color: white;
    background-color: #426B87;
  }
  
  .posts .paginationActive a {
    color: white;
    background-color: #426B87;
  }
  
  .posts .paginationDisabled a {
    color: grey;
  }
 .posts li.break{
    display: none;
 
  } 