.Availabletimes {
  background-color: #f9fbfd;
  width: 120%;
  padding: 24px;
}
.Availabletimes p {
  font-size: 16px;
  color: #757575;
  font-weight: 600;
}
.Availabletimes p:after {
  content: url("../../../../../../Resources/Assets/Path 10921.svg");
  display: block;
}
.timeContainer {
  height: 40px;
  margin-bottom: 22px;
  background-color: #fff;
  border: 1px solid #ebeffc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-family: "Gotham";
}
.clickable {
  background-color: #426b87;
  color: #fff;
}
.availableButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  position: relative;
  left: 65px;
  margin-bottom: 80px;
}
.aravailableButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  position: relative;
  right: 65px;
  margin-bottom: 80px;
}
.availableButton button {
  width: 148px;
  height: 37px;
  background-color: #426b87;
  border: none;
  color: #fff !important;
}
.aravailableButton button {
  width: 148px;
  height: 37px;
  background-color: #426b87;
  border: none;
  color: #fff !important;
}

@media only screen and (max-width: 768px) {
  .availableButton {
    position: relative;
    left: 0px;
  }
  .clickable {
    background-color: #426b87;
    color: #fff;
  }
  .aravailableButton {
    position: relative;
    right: 0px;
  }
  .availableButton button {
    position: relative;
    position: relative;
    right: 20px;
  }
  .aravailableButton button {
    position: relative;
    position: relative;
    right: 00px;
  }
  .Availabletimes {
    background-color: #f9fbfd;
    width: 100%;
    padding: 24px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .Availabletimes {
    width: 110% !important;
    padding-right: 15px;
  }
  .Availabletimes p {
    font-size: 12px;
  }
  .availableButton {
    position: relative;
    left: 0px;
  }
  .aravailableButton {
    position: relative;
    right: 0px;
  }
  .availableButton button {
    position: relative;
    position: relative;
    right: 20px;
  }
  .aravailableButton button {
    position: relative;
    position: relative;
    right: 20px;
  }
  .Availabletimes {
    background-color: #f9fbfd;
    width: 100%;
    padding: 24px;
  }
  .timeContainer {
    width: 94px;
    height: 40px;
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid #ebeffc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    font-family: "Gotham";
  }
  .clickable {
    background-color: #426b87;
    color: #fff;
  }
}
