.CompanySignupContainer{
  padding-top: 40px;
}
.CompanySignup .one{
  color:#426b87 ;
  font-size:12px ;
  font-family: "Poppins";
}
.CompanySignup .two{
  color:#426b87 ;
  font-size:12px ;
  font-family: "Poppins";
opacity: 0.2;
}
.CompanySignup .two p:last-of-type{
padding-top: 13px;}
.CompanySignupContainer  h4{
  font-weight: bold;
  font-family: "Open Sans";
  font-size: 19px;
}
.CompanySignup .formContainer .companyInfo
{
  color: #426B87;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
}
.arCompanySignup label{
  position: relative;
  top: 28px !important;
  right: 13px !important;
}
.CompanySignup button
{
  width: 148px;
  height: 37px;
  background-color: #426b87;
  color: #fff;
  border: none;
  margin-top: 25px;
}
.CompanySignup .checkAccount{
  color: #757575;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;

}
.CompanySignup .formContainer
{
  background-color: #fff;
  padding: 50px 36px;
  box-shadow: 5px 10px 8px 10px #ebeffc;
  width: 95% !important;

}
.CompanySignup .forgetPassCol
{
display: flex;
flex-direction: column;
justify-content: center;
}

/* @media only screen and (max-width: 768px) {

  .CompanySignup .forgetPassForm
  {
    background-color: #fff;
    padding: 50px 36px;
    box-shadow: 5px 10px 8px 10px #ebeffc;
    width: 100%;
  
  }
  .CompanySignup img
  {
    width: 100%;
  }
	.CompanySignup .LoginForm 
{
	display: flex;
height: 55vh;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
@-moz-document url-prefix() {
	.CompanySignup .LoginForm
	{
		display: flex;
	height: 56vh;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
  }

  } */