.TascCompanyInformation
{
    padding: 80px 73px;
}
.TascCompanyInformation .Ar
{
    text-align: right;
}

.TascCompanyInformation .title h1
{
 font-size: 30px;
 font-family: 'Poppins', sans-serif;
 width: 246px;
  line-height: 37px;
 font-weight: 700;
 text-transform: uppercase;
}
.TascCompanyInformation .title p
{
 font-size: 18px;
 font-family: 'Poppins', sans-serif;
 color: #426B87;
 display: inline-block;
 
}
.TascCompanyInformation .pragraph p:first-of-type:before{
    content: url("../../../../Resources/Assets/Path 10921.svg");
display: inline-block;
font-family: 'Open Sans', sans-serif;
font-size: 16px;
color: #000000;
padding-right: 40px;

}
.TascCompanyInformation .Ar .pragraph  p:first-of-type:before
{
    padding-left: 40px;
    padding-right: 0;

}
.TascCompanyInformation .pragraph p:first-of-type{
display: flex;
flex-direction: row;
list-style: none;
padding-left: 0px;
margin-left: 0px;
font-family: 'Open Sans', sans-serif;
font-size: 15.8px;
color: #000000;
opacity: 0.67;
margin-top: 6px;
margin-bottom: 35px;
padding-right: 28px;
/* opacity: 0.1; */
}
.TascCompanyInformation .Ar .pragraph  p:first-of-type
{
  
    padding-right: 0px !important;
    margin-right: 0px;
    padding-left: 28px !important;

}


.TascCompanyInformation .pragraph p:last-of-type{
list-style:none;
padding-left: 56px;
margin-left: 50px;
font-family: 'Open Sans', sans-serif;
font-size: 15.8px;
color: #000000;
opacity: 0.67;
margin-top: 40px;
margin-bottom: 50px;
padding-right: 22px;
/* opacity: 0.1; */
}
.TascCompanyInformation .Ar .pragraph  p:last-of-type{
    list-style:none;
padding-right: 56px;
margin-right: 50px;
font-family: 'Open Sans', sans-serif;
font-size: 15.8px;
color: #000000;
opacity: 0.67;
margin-top: 40px;
margin-bottom: 50px;
padding-left: 22px;
}
.TascCompanyInformation button
{
    width: 164px;
    height: 50px;
    color: #fff;
    background-color: #426B87;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-left: 110px;

}
.TascCompanyInformation button:hover
{
    width: 164px;
    height: 50px;
    color: #fff;
    background-color: #426B87;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}
/* .TascCompanyInformation .pragraph::before{
    content: "";
    display: inline-block;
    width: 67.92px;
    height: 5px;
    background-color: #426B87;
    margin-right: 50px;
    display: inline-block;
        flex-direction: row;
    margin-bottom: 120px;
    margin-right: 55px;
    
} */
/* .TascCompanyInformation .pragraph p:first-of-type{
display: inline-block;
width: 66%;
font-family: 'Poppins', sans-serif;
font-size: 16px;
opacity: 67%;
}
.TascCompanyInformation .pragraph p:last-of-type{
width: 68%;
margin-top: 25px;
margin-left: 119px;
font-family: 'Poppins', sans-serif;
font-size: 16px;
opacity: 67%;
} */
@media only screen and (max-width: 768px) {
    /* .TascCompanyInformation .pragraph p:last-of-type{
        padding-right: 20px;
    } */
    .TascCompanyInformation .pragraph p:first-of-type{
        padding-right: 20px;
        display: flex;
        flex-direction: column;
    }

    .TascCompanyInformation
{
    padding: 80px 13px;
}
.TascCompanyInformation .ul p:first-of-type{
   
    padding-right: 0px;
    padding-left: 15px;
    }
    .TascCompanyInformation .ul p:last-of-type{

    padding-right: 0px;
    margin-top: 25px;
    margin-bottom: 40px;
    padding-left: 8px;

    }
    .TascCompanyInformation button
    {
    margin-bottom: 40px;
    margin-left: 100px;
    
    }
    .TascCompanyInformation .pragraph p:last-of-type{
        list-style:none;
        padding-left: 0;
        margin-left: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: #000000;
        opacity: 0.67;
        margin-top: 40px;
        margin-bottom: 55px;
        padding-right: 0;
        }
}
@media  (min-width: 768px) and (max-width: 991px) {
    .TascCompanyInformation
    {
        padding: 80px 0px;
    }
    .TascCompanyInformation .ul p:first-of-type{
   
        padding-right: 0px;
        padding-left: 15px;

        
        }
        .TascCompanyInformation .ul p:last-of-type{
    
        padding-right: 0px;
        margin-top: 25px;
        margin-bottom: 40px;
        padding-left: 8px;

        }
        .TascCompanyInformation button
{
margin-bottom: 40px;
margin-left: 100px;

}
    /* .TascCompanyInformation .pragraph::before{
  
        margin-bottom: 268px;
     
        
    }
    .TascCompanyInformation .pragraph p:last-of-type{
        margin-top: 15px;
     
        } */
}

