
.ForgetPasswordFormContainer label
{
  top: 48px;
}
.ForgetPasswordFormContainer button
{
  width: 148px;
  height: 37px;
  background-color: #426b87;
  color: #fff;
  border: none;
  margin-top: 25px;
}
.ForgetPasswordFormContainer .checkAccount{
  color: #757575;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;

}
.ForgetPasswordFormContainer .forgetPassForm
{
  background-color: #fff;
  padding: 50px 36px;
  box-shadow: 5px 10px 8px 10px #ebeffc;
  width: 90%;

}
.ForgetPasswordFormContainer .forgetPassCol
{
display: flex;
flex-direction: column;
justify-content: center;
}

@media only screen and (max-width: 768px) {

  .ForgetPasswordFormContainer .forgetPassForm
  {
    background-color: #fff;
    padding: 50px 36px;
    box-shadow: 5px 10px 8px 10px #ebeffc;
    width: 100%;
  
  }
  .ForgetPasswordFormContainer img
  {
    width: 100%;
  }
	.ForgetPasswordFormContainer .LoginForm 
{
	display: flex;
height: 55vh;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
@-moz-document url-prefix() {
	.ForgetPasswordFormContainer .LoginForm
	{
		display: flex;
	height: 56vh;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
  }

  }