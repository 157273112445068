.GetInTouch {
  padding-top: 70px;
}
.GetInTouch li {
  list-style: none;
}
.GetInTouch ul li h4 {
  font-size: 24px;
  font-weight: 500;
  font-family: "Gotham";
  color: #426b87;
  margin: 0;
}
.GetInTouch ul p {
  padding: 0;
  margin: 0;
}
.GetInTouch ul li ul {
  margin-left: 38px;
  display: flex;
}
.GetInTouch ul li ul li {
  margin-left: 30px;
}
.GetInTouch p {
  font-family: "Open Sans";
  font-size: 16px;
  opacity: 0.67;
}
.GetInTouch .title {
  margin-bottom: 65px;
}
.GetInTouch .title h2 {
  font-size: 32px;
  font-weight: bold;
  font-family: "Gotham";
  color: #426b87;
  display: flex;
  margin-right: 3px;
}
.GetInTouch .title ::before {
  content: url("../../../../Resources/Assets/Line 42.svg");
  /* display: flex; */
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #000000;
  margin-top: 12px;
  margin-right: 30px;
  flex-direction: row;
}

.GetInTouch .getTouchForm {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 768px) {
  .GetInTouch .title h2 {
    font-size: 18px;
    font-weight: bold;
    font-family: "Gotham";
    color: #426b87;
    display: flex;
  }
  .GetInTouch .title ::before {
    margin-top: 0;
  }
  .GetInTouch ul li ul {
    margin-left: 0;
  }
  .GetInTouch ul li h4 {
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .GetInTouch .title h2 {
    font-size: 18px;
    font-weight: bold;
    font-family: "Gotham";
    color: #426b87;
    display: flex;
  }
  .GetInTouch .title ::before {
    margin-top: 0;
  }
  .GetInTouch ul li h4 {
    font-size: 18px;
  }
  .GetInTouch .touchContainer {
    margin: 0;
  }
  .GetInTouch .touchContainer li {
margin-left: 0;
margin-right: 18px;
}
}
