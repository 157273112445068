.GeneralManager {
  padding: 0px 0 0px 73px;
}
.GeneralManager .RightManger
{
  position: relative;
  right: 73px;
  top: 28px;

}
.GeneralManager .arGeneralManager div:first-of-type
{
 margin-left: 75px;
 text-align: right;
}
.GeneralManager ul {
  display: flex;
  flex-direction: row;
}
.GeneralManager .GeneralManagerImg {
  height: 569px;
  width: 608px;
  position: relative;
  top: 28px;
}
.GeneralManager ul li {
  list-style: url("../../../../Resources/Assets/checklistIcon.svg");
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  opacity: 0.67;
  padding-left: 16px;
  margin-left: -16px;
  padding-left: 9px;
}
.GeneralManager ul li:first-of-type {
  width: 315px;
}
.GeneralManager ul div:first-of-type li:last-of-type {
  width: 300px;
  margin-top: 30px;
}
.GeneralManager ul div:first-of-type {
  margin-right: 75px;
}

.arGeneralManager{
  padding: 0;
  margin: 0;
}
.arGeneralManager .Title
{
  margin-right: 60px;
}

@media only screen and (max-width: 768px) {
  .GeneralManager {
    padding: 0 0px 50px 15px;
    position: relative;
    top: 35px;
  }
  .GeneralManager .arGeneralManager div{
    margin-right: 0 !important;
  }
  .GeneralManager .arabic .Title .dark{
    position: relative;
    bottom: 46px;
    right: 22px !important;
    font-size: 18px;
    color: #426B87;
    font-family: 'Poppins', sans-serif;
  }
  .GeneralManager ul li {
    padding-left: 0px;
  }
  .GeneralManager ul {
    display: flex;
    flex-direction: column;
  }
  .GeneralManager ul div:first-of-type li:last-of-type {
    margin-bottom: 30px;
  }
  .GeneralManager ul li:first-of-type {
    width: 300px;
    margin-bottom: 25px;
  }
  .GeneralManager .GeneralManagerImg {
    height: 500px;
  }
  .GeneralManager .RightManger
{
  position: relative;
  right: 15px;
  top: 35px;

}
}
@media (min-width: 768px) and (max-width: 991px) {
  .GeneralManager {
    padding: 80px 0 0px 0px;
  }
  .GeneralManager ul li {
    padding-left: 0px;
  }
  .GeneralManager ul {
    display: flex;
    flex-direction: column;
  }
  .GeneralManager ul div:first-of-type li:last-of-type {
    margin-bottom: 30px;
  }
  .GeneralManager ul div:last-of-type{  
  margin-left: 75px;
text-align: right;
margin-right: 75px;
}
  .GeneralManager ul li:first-of-type {
    width: 300px;
    margin-bottom: 25px;
  }
  .GeneralManager .RightManger
{
  position: relative !important;
  left: 15px !important;
  right: 0;
  top: 35px;

}
}
