.AccountingManager
{
background-image: url("../../../../Resources/Assets/Account6009.png");
height: fit-content;
background-repeat: no-repeat;
background-size: 100% 100%;
width: 100% !important;
padding-top: 105px;
padding-bottom: 70px;
}
.arAccountingManager
{
background-image: url("../../../../Resources/Assets/RightAccount.png");
height: fit-content;
background-repeat: no-repeat;
background-size: 100% 100%;
width: 100% !important;
padding-top: 105px;
padding-bottom: 70px;
text-align: right;
}
.AccountingManager .textContainer,.arAccountingManager .textContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AccountingManager ul ,.arAccountingManager ul
{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    position: relative;
    bottom: 30px;
    align-items: center;
}
.AccountingManager  li span,.arAccountingManager li span{
    color: #fff;
    opacity: 0.67
}
.AccountingManager  li 
{
    margin-bottom: 20px;
    font-size: 16px;
    width: 72%;
    padding-left: 14px;
  list-style: url("../../../../Resources//Assets/lightCheck.svg");
}
.arAccountingManager  li 
{
    margin-bottom: 20px;
    font-size: 16px;
    width: 72%;
    padding-right: 14px;
  list-style: url("../../../../Resources//Assets/lightCheck.svg");
}
/* .AccountingManager ul li::before
{
  content: url("../../../../Resources//Assets/lightCheck.svg");
  display: inline-block; 
color: #fff;
opacity: 100% !important;
  
} */

.AccountingManager ul li::marker,.arAccountingManager ul li::marker
{
  color: #fff !important;
  opacity: 1 !important;
}
@media only screen and (max-width: 768px) {
    .AccountingManager
    {
padding: 50px 20px 50px 20px;
    }
    .AccountingManager ul li
{

    width: 95%;

  
}
}
@media  (min-width: 768px) and (max-width: 991px) {
    .AccountingManager
    {
padding: 50px 20px 50px 20px;
    }
}