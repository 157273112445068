.navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: transparent !important;
  padding-top: 15px;
}

/* .lightNavbar
{
	background-color: #fff !important;
} */
.navbar .nav-links a {
  font-family: "Gotham";
  src: url('././../../../Resources/Fonts/GothamMedium_1.ttf'); /* IE9 Compat Modes */
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 7px;
}
.navbar .dropdown-menu
{
  background-color: #F9FBFD;
}

.lightNavbar .lightLink {
  color: #426b87 !important;
  font-family: "Gotham";
  font-size: 16px;
  font-weight: 500;
}
.navbar .login p{
  font-family: "Gotham";
  src: url('././../../../Resources/Fonts/GothamMedium_1.ttf'); 
  font-size: 16px;
  font-weight: 500;}
.navbar .login {
  padding-top: 25px;
}
.lightNavbar .lightlogin p {
  color: #426b87 !important;
  margin-left: 20px;
  font-family: "Gotham";
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
}
.navbar .active::before {
  content: url("../../../Resources/Assets/Path\ 10840.svg");
  display: inline-block;
  position: relative;
  top: 10px;
  left: 4px;
}
.navbar .arcontainer .active::before {
  content: url("../../../Path\ 10840.svg");
  display: inline-block;
  position: relative;
  top: 10px;
  left: 4px;
}
.navbar .arcontainer .homeActive::before {
  content: url("../../../Resources/Path 10840.svg");
  display: inline-block;
  position: relative;
  top: 10px;
  left: 4px;
}
.navbar .active {
	color: #426b87 !important;
}
.navbar .homeActive::before {
  content: url("../../../Resources/Assets/activeState.svg");
  display: inline-block;
  position: relative;
  top: 10px;
  left: 4px;
}

.navbar .navbar-nav > .nav-item > a {
  position: relative;
}
.navbar .langouage {
  width: 100% !important;
}
.navbar .dropdown {
  display: flex;
  justify-content: flex-end;
}
.navbar .dropdown-toggle {
  width: 164px;
  height: 43.04px;
  border: 1px solid #426b87;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #426b87 !important;
}
.navbar .dropdown-toggle img {
  margin-right: 5px;
}

.navbar .dropdown-toggle::after {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .arcontainer .navbar-collapse{
    position: absolute;
      top: 80px;
      left: 0 !important;}
      .arcontainer .navbar-collapse{
        position: absolute;
          top: 80px;
          left: 0 !important;
        right: 50%;
        text-align: right;
        padding-right: 25px;
        }
   
        .TascCompanyInformation .Ar .pragraph p:last-of-type {
          list-style: none;
          padding-right: 0px !important;
          margin-right: 0px !important;
        }
  .navbar-toggler {
    background-color: #426b87;
  }

  .navbar-collapse {
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.8);
    width: 50%;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .navbar-collapse a {
    padding-bottom: 10px;
    color: #426b87 !important;
  }
  .navbar-nav .login a {
    margin-left: 20px;
  }
  .navbar .nav-links a {
    margin-left: 20px !important;
  }
  .navbar .nav-links .active {
    margin-left: 7px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .arcontainer .navbar-collapse{
    position: absolute;
      top: 80px;
      left: 0 !important;
    right: 50%;
    text-align: right;
    padding-right: 25px;
    }
  .navbar-toggler {
    background-color: #426b87;
  }
  .navbar-collapse {
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.8);
    width: 50%;
    padding-top: 25px;
    padding-bottom: 15px;
  }
  .navbar-collapse a {
    padding-bottom: 10px;
    color: #426b87 !important;
  }
  .navbar-nav .login a {
    margin-left: 20px;
  }
}
