.footer {
	background-image: url("../../../Resources//Assets/Footer.png");
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	color: #fff;
	margin-top: auto;
	padding-top: 70px;
	padding-bottom: 70px;
	padding-left: 50px;
	padding-right: 50px;
	/* background-color: #1F394A; */
}
.footer .footer-img p{
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 500;
	padding-right: 74px;
	/* margin-left: 27px;
	padding-top: 3.5rem; */

}
.footer .text-right .footer-img p{
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 500;
	padding-left: 74px;
	padding-right:0;
	/* margin-left: 27px;
	padding-top: 3.5rem; */

}
.footer .footer-img {
    position: absolute;
    bottom: -6px;
    left: 31px;

}
.footer .footer-img img{
  margin-bottom: 12px;

}
.footer .social-medoia
{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-left: 0;
	margin-top: 40px;
}
.footer .social-medoia .facebook
{
margin-right: 0.5rem;
}
.footer .social-medoia
{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-left: 0;
	margin-top: 40px;
}
.footer .arrow-container
{
	width: 41px;
	height: 41px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	cursor: pointer;
	margin-left:65px;
}
.footer .dropdown-toggle
{
	width: 164px;
	height: 43.04px;
	border: 1px solid #fff;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	margin-bottom: 23px;}
.footer .dropdown-toggle img
{
margin-right: 5px;
}

.footer .dropdown-toggle::after
{
margin-top: 10px;
}
.footer .footer-links> div:first-of-type{
	margin-right:16px;
}
.footer .text-right .footer-links>div:first-of-type
{
	margin-left: 16px;
	margin-right: 0;
}
.footer .social-medoia img{
cursor: pointer;
}
.footer .footer-links
{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	color: #fff;
	opacity: 0.69;
	margin-top: 4.6rem;
	position: relative;
bottom: 38px;
}
.footer .row{
	position: relative;
}
.footer .dropdown-menu
{
  background-color: #F9FBFD;
}
.footer .social-medoiaCol{
	position: absolute;
	right: 0px;
	top: 11px;
}
.footer .text-right .social-medoiaCol{
	position: absolute;
	left: 0px !important;
	top: 11px;
}


.footer .footer-links ul li a
{
text-decoration: none;
}
.footer .footer-links ul li 
{

	list-style: none;
	padding-left: 15px;
}
.footer.text-right .footer-links ul li 
{

	list-style: none;
	padding-right: 15px;
	padding-left: 0px;
}
.footer .arrow
{
	display: flex;
	width: 100%;
	justify-content: flex-end;
margin-left: 57px;	
}
.footer .active::before
{
	content: url("../../../Resources/Assets/activeState.svg") ;
	display: inline-block;
	position: relative;
top: 8px;
left: 4px;
}
.footer .text-right .active::before
{
	content: url("../../../Resources/Path 10840.svg");
	display: inline-block;
	position: relative;
	top: 10px;
	left: 4px;
}

@media only screen and (max-width: 768px) {
	.footer .footer-img p{
	
		padding-right: 20px;
	
	}
	footer .text-right .footer-links ul li{
		font-size: 14px;
	} 
	.footer .footer-links
	{
	
		justify-content: flex-start;
		margin-top: 0.6rem;
	}
	.footer .footer-links a
	{
	
		padding-right: 0rem !important;
	}
	.footer .footer-links ul
	{
	
padding-left: 0;
	}
	.footer .arrow
{

	justify-content: center;
	padding-right: 67px;
}
.footer .footer-img,.footer .social-medoiaCol {
    position: static;
	margin-top: 20px;
}


  }
  @media  (min-width: 768px) and (max-width: 991px) {
.footer{
	padding-left: 20px;
padding-right: 80px;
}

	.footer .footer-links
	{
	
		justify-content: flex-start;
		margin-top: 2.6rem;
	}
	.arfooter .footer-links
	{
		justify-content: flex-end;

	}
	.arfooter .footer-links ul li a
	{

		font-size: 12px !important;
	}
	.arfooter .text-right .footer-links > div:first-of-type
	{
		margin-left: 0;
	}
	.arfooter .footer-links ul
	{
		margin: 0;
		padding: 0;
	}
	.arfooter .footer-links ul:first-of-type
	{
		margin-left:15px;
	
	}
	.footer .arrow
	{
	
		justify-content: center;
		padding-right: 67px;
	}
	.footer .footer-links ul
	{
	
padding-left: 0;
	}
	.footer .footer-links ul li
	{
	
   padding-left: 0;
	}
	.footer .footer-links ul li a
	{
	
   padding-left: 0 !important;
	}
	.footer .footer-img,.footer .social-medoiaCol {
		position: static;
	   
	margin-top: 20px;
	}
  } 