.TascActivity {
  background-image: url("../../../../Resources/Assets/Group 6493.png");
  background-attachment: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 1286px !important; */
  background-position-x: right;
  margin-left: 118px;
  height: 610px;
  /* height: fit-content; */
}
.arTascActivity{
  background-image: url("../../../../Resources/Assets/RightAbout.png");
  background-attachment: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 1286px !important; */
  background-position-x: left;
  margin-right: 0px;
  height: 610px;
  /* height: fit-content; */
}
.TascActivity .activityContent {
  position: absolute;
  top: 115px;
  left: 50px;
}
.TascActivity .aractivityContent {
  position: absolute;
  top: 115px;
  right: 38px;
}
.TascActivity .title h2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 33px;
}
.TascActivity .title div {
  display: flex;
  flex-direction: row;
}
.TascActivity .title div img {
  margin-left: 0 !important;
}
.TascActivity .title div p {
  width: 226px;
  padding-top: 21px;
}
.TascActivity .activityContent h2 {
  font-family: "Poppins";
  font-size: 30;
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
}
.TascActivity .aractivityContent h2 {
  font-family: "Poppins";
  font-size: 30;
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
}
.TascActivity .activityContent p:first-of-type {
  font-family: "Poppins";
  font-size: 18;
  color: #4eaee5;
  margin-bottom: 24px;
}
.TascActivity .aractivityContent p:first-of-type {
  font-family: "Poppins";
  font-size: 18;
  color: #4eaee5;
  margin-bottom: 24px;
}
.TascActivity .activityContent p:last-of-type {
  font-family: "Poppins";
  font-size: 18;
  color: #fff;
}
.TascActivity .aractivityContent p:last-of-type {
  font-family: "Poppins";
  font-size: 18;
  color: #fff;
}
.TascActivity .DevelopmentContent {
  background-color: #fff;
  width: 582px;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 35px;
  box-shadow: rgba(44, 44, 212, 0.2) 0px 3px 30px 0px;
  position: absolute;
  left: -11px;
  height: 230px;
}
.TascActivity .arDevelopmentContent {
  background-color: #fff;
  width: 582px;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-right: 35px;
  box-shadow: rgba(44, 44, 212, 0.2) 0px 3px 30px 0px;
  position: absolute;
  left: -11px;
  height: 230px;
  padding-left: 25px;
}
.TascActivity .DevelopmentContent h2 {
  font-family: "Poppins";
  font-size: 18px;
  color: #426b87;
  font-weight: 600;
}
.TascActivity .arDevelopmentContent h2 {
  font-family: "Poppins";
  font-size: 18px;
  color: #426b87;
  font-weight: 600;
}

.TascActivity .DevelopmentContent p {
  font-family: "Open Sans";
  font-size: 16;
  opacity: 0.67;
}
.TascActivity .arDevelopmentContent p {
  font-family: "Open Sans";
  font-size: 16;
  opacity: 0.67;
}
@media only screen and (max-width: 768px) {
  .TascActivity {
    /* width: 1286px !important; */
    background-image: none;
    background-position-x: right;
    margin-left: 1px;
    height: 610px;
    margin-bottom: 270px;
  }
  .TascActivity .darkSkyContainer {
    background-image: url("../../../../Resources/Assets/darkSky.png");
    background-attachment: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .TascActivity .aboutManContainer {
    background-image: url("../../../../Resources/Assets/aboutManContainer.png");
    background-attachment: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .TascActivity .DevelopmentContent {
    width: 103%;
    height: fit-content;
    top: 240px;
    padding-left: 16px;
  }
  .TascActivity .arDevelopmentContent {
    width: 99%;
    height: fit-content;
    top: 240px;
    left: 3px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .TascActivity {
  
    background-image: url("../../../../Resources/Assets/Group 6493.png");
    background-attachment: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 610px;
    margin-left: 1px;

  }

  .TascActivity .DevelopmentContent {
    width: auto;

    height: fit-content;
    top: 376px;
  }
  .TascActivity .arDevelopmentContent {
    width: 99%;
    height: fit-content;
    top: 376px;
    
  }
}
